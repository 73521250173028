import { adminClient } from "@api/admin/AdminClient";
import CodeTextArea from "@components/admin/CodeTextArea";
import { withAdminMenuLayout } from "@components/admin/Decorators";
import { queryApi } from "@hooks/api";
import { Box, Button } from "@material-ui/core";
import { performAction } from "@utils/actions";
import { useEffect, useState } from "react";

export default withAdminMenuLayout(function ConfigurationPageComponent() {
    const [json, setJson] = useState("");

    const [configFromServer] = queryApi(
        async (options) => {
            const response = await adminClient().adminConfigurationCurrentConfigurationJsonGet(options);
            return response.result;
        }, 
        []);

    useEffect(
        () => setJson(configFromServer || ""),
        [configFromServer]);

    async function saveJson() {
        await performAction(
            async () => await adminClient().adminConfigurationSaveJsonPost({ body: {
                json
            }}),
            "An error occured. Are you sure the configuration JSON is valid?");
    }

    return <>
        <Box>
            <CodeTextArea
                code={json}
                onCodeChanged={code => setJson(code)}
            />
            <Button
                color="primary"
                variant="contained"
                onClick={() => saveJson()}
                style={{
                    marginTop: 16
                }}
            >
                Save
            </Button>
        </Box>
    </>
});